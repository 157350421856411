import React from 'react';
import logo from './logo.svg';
import './App.css';
import { RootReducer } from './reducers/RootReducer';
import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';
import { createStore, applyMiddleware } from 'redux';
import { InitialState } from './InitialState';
import ReduxThunk from 'redux-thunk';
import { HomeScreenContainer } from './screens/HomeScreen';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { SorayaScreenContainer } from './screens/SorayaScreen';
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from 'react-redux';

//AXIOS client
const client = axios.create({
  baseURL: '',
  responseType: 'json'
});

// A very simple store
let store = createStore(
  RootReducer,
  InitialState,
  applyMiddleware(axiosMiddleware(client), ReduxThunk),
);

export const dispatch = store.dispatch;


function App() {
  return (
    <Auth0Provider
      domain="jellycore.auth0.com"
      clientId="NXCSA7Fbahv3hyF3VwbXYUrZ1gD2yD6A"
      redirectUri={window.location.href}
    >
      <Provider store={store}>
        <Router>
          <Route path="/" exact component={HomeScreenContainer} />
          <Route path="/soraya" exact component={SorayaScreenContainer} />
        </Router>
      </Provider>
    </Auth0Provider>
  );
}

export default App;
