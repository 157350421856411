import { useEffect, useState } from "react";
import graphy from './../images/graphy.png'
import en from './../images/en-logo.png';
import { Link, useHistory } from "react-router-dom";

export const NavBar = (props: {
    isProject: boolean,
    projectName?: string,
}) => {
    const [scrolling, setScrolling] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    const history = useHistory();

    useEffect(() => {
        const onScroll = (e: any) => {
            setScrollTop(e.target.documentElement.scrollTop);
            setScrolling(e.target.documentElement.scrollTop > 10);
            // console.log(e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

    return (
        <div id="navbar" style={{ backgroundImage: `url(${graphy})`, backgroundRepeat: "repeat", backgroundColor: props.isProject ? "#ffbf00" : "#ffffff" }} className={scrolling ? "minimized" : "expanded"}>

            <img onClick={() => history.push("/")} className={scrolling ? "minimized" : "expanded"} src={en} />
            <span style={{ fontWeight: 100 }}>{props.isProject ? props.projectName : "Emilio Navarro"}</span>


            {/* <div id="navbar-right">
                <a className="active" href="#home">Home</a>
                <a href="#contact">Contact</a>
                <a href="#about">About</a>
            </div> */}
        </div>
    );
}