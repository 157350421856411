
import { NavBar } from "../components/NavBar";
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import en from './../images/en-logo.png';
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@material-ui/core/Button';
import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import * as SorayaActions from '../actions/SorayaActions';

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: "#9CCC65",
        borderRadius: 15,
        color: 'white',
        paddingLeft: 15
    },
    plantTitle: {
        fontSize: 48,
        width: '100%',
        maxWidth: '100%',
        fontWeight: 500,
    },
    plantTitleContainer: {
        marginTop: 10
    },
    deviceId: {
        fontSize: 18,
        width: '100%',
        maxWidth: '100%',
        flexDirection: "row",
        alignSelf: 'flex-end',
        color: '#ECEFF1',
        display: "flex",
        justifyContent: "flex-end",
        fontWeight: 500
    },
    observationsContainer: {
        width: '100%',
        marginTop: 25,
    },
    observationTitle: {
        fontSize: 24,
        color: "#ECEFF1",
        width: '100%'
    },
    observationButtonsContainer: {
        display: "flex",
        justifyContent: "flex-start",
        width: '100%',
        maxWidth: '100%',
        flexDirection: "row",
        alignSelf: 'flex-start'
    },
    plantActionButton: {
        textTransform: "none",
        color: "#2E7D32",
        fontSize: 18,
        fontWeight: 700
    }
});

const renderPlantCard = (classes: any, props: any) => {
    return <Card className={classes.root} onClick={() => { }}>
        <CardContent className={classes.plantTitleContainer}>

            <span className={classes.plantTitle}>{props.device ? (props.device as any)?.plantName : null}</span>
            <span className={classes.deviceId}>{props.device ? (props.device as any)?.deviceId : null}</span>

        </CardContent>

        <CardContent className={classes.observationsContainer}>
            <span className={classes.observationTitle}>o b s e r v a t i o n s</span>
            <div className={classes.observationButtonsContainer}>
                <Button variant="outlined" className={classes.plantActionButton} onClick={() => { props.createServiceRequest(props.accessToken, props.device.deviceId, "moisture"); }}>
                    Update
                </Button>
                <Button variant="outlined" style={{ marginLeft: 5 }} className={classes.plantActionButton} onClick={() => { props.createServiceRequest(props.accessToken, props.device.deviceId, "hydrate"); }}>
                    Hydrate
                </Button>
            </div>
        </CardContent>

        <CardContent style={{ fontSize: 24, color: "#ECEFF1" }} className={classes.observationsContainer}>

        </CardContent>

    </Card>
}

const PlantCard = (props: {
    device: any,
    devices: Array<any>,
    accessToken: string,
    getDevice: any,
    serviceRequestCreated: boolean,
    creatingServiceRequest: boolean,
    createServiceRequest: any
}) => {

    useEffect(() => {
        const device = props.devices.find(d => d === props.device);

        if (device && device.plantName) {
        } else if (device) {
            props.getDevice(props.accessToken, props.device)
        }
    })
    const classes = useStyles();

    console.log("CURRENT DEVICE");
    console.log(props.device);
    return props.device && props.device.plantName ? renderPlantCard(classes, props) : <Card></Card>;
}


const mapStateToProps = (state: any) => ({
    accessToken: state.soraya.accessToken,
    devices: state.soraya.devices,
    serviceRequestCreated: state.soraya.serviceRequestCreated,
    creatingServiceRequest: state.soraya.creatingServiceRequest
});

const mapDispatchToProps = {
    getDevice: SorayaActions.getDevice,
    createServiceRequest: SorayaActions.createServiceRequest
};

export const PlantCardContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PlantCard);