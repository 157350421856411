import * as PlantActions from '../actions/SorayaActions';

export const SorayaReducer = (
    state: any,
    action: any
): any => {

    switch (action.type) {

        case PlantActions.CREATE_SERVICE_REQUEST: {
            return {
                ...state,
                creatingServiceRequest: true
            }
        }

        case PlantActions.CREATE_SERVICE_REQUEST_SUCCESS: {
            console.log(action.payload.data);
            return {
                ...state,
                serviceRequestCreated: true,
                creatingServiceRequest: false
            }
        }

        case PlantActions.CREATE_SERVICE_REQUEST_FAILURE: {
            console.log(action);
            return {
                ...state,
                serviceRequestCreated: false,
                creatingServiceRequest: false
            }
        }

        case PlantActions.GET_DEVICES_SUCCESS: {
            console.log(action.payload.data);
            return {
                ...state,
                devices: action.payload.data
            }
        }

        case PlantActions.GET_DEVICES_FAILURE: {
            console.log("BORKED");
            return {
                ...state
            }
        }

        case PlantActions.GET_DEVICE_SUCCESS: {
            const devices = state.devices;
            const updatedDevice = action.payload.data.Item;
            const devicesWithPartialInfo = devices.map((deviceId: string) => {
                if (deviceId === updatedDevice.deviceId.S) {
                    return {
                        deviceId: updatedDevice.deviceId.S,
                        plantName: updatedDevice.plantName.S
                    }
                } else {
                    return deviceId;
                }
            });
            console.log(devicesWithPartialInfo);

            return {
                ...state,
                devices: devicesWithPartialInfo
            }
        }

        case PlantActions.GET_DEVICE_FAILURE: {
            console.log("BORKED");
            return {
                ...state
            }
        }

        case PlantActions.GET_ACCESS_TOKEN_SUCCESS: {
            return {
                ...state,
                accessToken: action.payload.data.access_token
            }
        }

        case PlantActions.GET_ACCESS_TOKEN_FAILURE: {
            console.log("BORKED - access token");
            return {
                ...state
            }
        }

        default:

            return {
                ...state
            }
    }

}