
import { NavBar } from "../components/NavBar";
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import en from './../images/en-logo.png';
import { Link, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import * as SorayaActions from '../actions/SorayaActions';
import { useEffect } from "react";
import sorayaBg from './../images/soraya-bg-green-yellow.png';
import awsCpBg from './../images/aws-cp-bg.png';
import plantIcon from './../images/plant.png';
import awsIcon from './../images/aws-cp.png';
import achievrBg from './../images/achievr-bg.png';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import {
    Pattern as CustomPattern,
    PatternLines,
    PatternCircles,
    PatternWaves,
} from '@visx/pattern';
import Example from "./Example";

const useStyles = makeStyles({
    root: {
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        cursor: "pointer",
        maxHeight: '200px',
        minHeight: '200px',
        margin: '1rem',
        width: '90%',
        // minWidth: '240px'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 24,
    },
    pos: {
        marginBottom: 12,
    },
    cardSection: {
        textAlign: "center",
        alignItems: "center",
        justifyContent: 'center',
        display: "flex",
        color: '#ffffff',
        width: '100%',
        gridGap: '1rem'
    }
});

export const HomeScreen = (props: {

}) => {

    const classes = useStyles();
    const history = useHistory();

    return <div>
        <NavBar isProject={false} projectName="Project" />

        <section className="sheet" style={{ marginTop: 250 }}>
            <h1>Projects</h1>
            <div className="projects">
                <Card className='project-card'
                    style={{ backgroundImage: `url(${sorayaBg})`, backgroundSize: "cover" }}
                    onClick={() => {

                        history.push('/soraya')
                    }}>
                    <CardContent className='project-card-body'>
                        <img src={`${plantIcon}`} />
                        <span>
                            Soraya
                        </span>
                    </CardContent>
                </Card>
                <Card
                    className='project-card'
                    style={{ backgroundImage: `url(http://jellycore.net/bg.ce5a4de9e0bcf45134ad.png)`, backgroundSize: "cover" }}
                    onClick={() => {

                        window.location.href = "http://jellycore.net"
                    }}
                >
                    <CardContent className='project-card-body'>
                        <img src="http://jellycore.net/assets/jc-logo.png" className='jc-img' />
                    </CardContent>
                </Card>
                <Card className='project-card'
                    style={{ backgroundImage: `url(${awsCpBg})`, backgroundSize: "cover" }}
                    onClick={() => {

                        window.location.href = "https://github.com/emilionavarro/aws-cp-tests";
                    }}>
                    <CardContent className='project-card-body'>
                        <img src={`${awsIcon}`} />
                        <span style={{ fontWeight: 300, color: '#424242' }}>
                            AWS CP Tester
                        </span>
                    </CardContent>
                </Card>
                <Card className='project-card'
                    style={{ backgroundImage: `url(${achievrBg})`, backgroundSize: "cover" }}
                    onClick={() => {

                        window.location.href = "https://achievr-ui.vercel.app/";
                    }}>
                    <CardContent className='project-card-body' style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <Example width={80} height={80} />

                        <span style={{ fontWeight: 300, color: '#424242' }}>
                            Achievr
                        </span>
                    </CardContent>
                </Card>
            </div>
            <div>

            </div>

        </section>

    </div>

    {/* <div style={{ marginTop: 250 }}>

            <h1>Projects</h1>

            <div className="projects-container">

                <Card className={classes.root} onClick={() => {

                    history.push('/soraya')
                }}>
                    <CardContent className={classes.cardSection}>
                        <img src="https://i.pinimg.com/originals/56/58/e5/5658e5437e84cd84c48d1546233bb936.jpg" style={{ width: "250%" }} />
                    </CardContent>
                    <CardContent className={classes.cardSection}>
                        <span style={{ fontWeight: 100, fontSize: 32 }}>
                            soraya
                        </span>
                    </CardContent>
                </Card>

                <Card className={classes.root}>
                    <CardContent className={classes.cardSection}>
                        <img src="https://i.pinimg.com/474x/7a/12/74/7a12745c9b4f119782f13e8dfe5ca690.jpg" style={{ width: "100%", opacity: .75 }} />
                    </CardContent>
                    <CardContent className={classes.cardSection}>
                        <span style={{ fontWeight: 100, fontSize: 32 }}>
                            DnD Tool
                        </span>
                    </CardContent>
                </Card>

                <Card
                    className={classes.root}
                    style={{ backgroundImage: "url(http://jellycore.net/bg.ce5a4de9e0bcf45134ad.png)", backgroundSize: "cover" }}
                    onClick={() => {

                        window.location.href = "http://jellycore.net"
                    }}
                >
                    <CardContent className={classes.cardSection}>
                        <img src="http://jellycore.net/assets/jc-logo.png" style={{ width: "100%", opacity: .75 }} />
                    </CardContent>
                </Card>

            </div>

            <h1>Me</h1>

            <div className="projects-container">

                <Card className={classes.root} onClick={() => {

                    window.location.href = "https://www.linkedin.com/in/emilio-navarro-19314490/"
                }}>
                    <CardContent className={classes.cardSection}>
                        <img src="https://cdn2.iconfinder.com/data/icons/simple-social-media-shadow/512/14-512.png" style={{ width: "90%" }} />
                    </CardContent>
                    <CardContent className={classes.cardSection}>
                        <span style={{ fontWeight: 100, fontSize: 32 }}>
                            LinkedIn
                        </span>
                    </CardContent>
                </Card>

            </div>

        </div>
    </div> */}

}

const mapStateToProps = (state: any) => ({
    accessToken: state.soraya.accessToken,
    devices: state.soraya.devices
});

const mapDispatchToProps = {
    getAccessToken: SorayaActions.getAccessToken,
    getDevices: SorayaActions.getDevices
};

export const HomeScreenContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeScreen);