
export const GET_DEVICES = 'GET_DEVICES';
export const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS';
export const GET_DEVICES_FAILURE = 'GET_DEVICES_FAILURE';
export const GET_DEVICE = 'GET_DEVICE';
export const GET_DEVICE_SUCCESS = 'GET_DEVICE_SUCCESS';
export const GET_DEVICE_FAILURE = 'GET_DEVICE_FAILURE';
export const GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN';
export const GET_ACCESS_TOKEN_SUCCESS = 'GET_ACCESS_TOKEN_SUCCESS';
export const GET_ACCESS_TOKEN_FAILURE = 'GET_ACCESS_TOKEN_FAILURE';
export const CREATE_SERVICE_REQUEST = 'CREATE_SERVICE_REQUEST';
export const CREATE_SERVICE_REQUEST_SUCCESS = 'CREATE_SERVICE_REQUEST_SUCCESS';
export const CREATE_SERVICE_REQUEST_FAILURE = 'CREATE_SERVICE_REQUEST_FAILURE';

export const getDevices = (accessToken: string) => {

    const url = "https://batxy5xsne.execute-api.us-east-2.amazonaws.com/dev/devices";

    return {
        type: GET_DEVICES,
        payload: {
            request: {
                url,
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                method: "GET"
            }
        }
    }
}

export const getDevice = (accessToken: string, deviceId: string) => ({
    type: GET_DEVICE,
    payload: {
        request: {
            url: `https://batxy5xsne.execute-api.us-east-2.amazonaws.com/dev/devices?deviceId=${deviceId}`,
            headers: {
                "Authorization": `Bearer ${accessToken}`
            },
            method: "GET"
        }
    }
})

export const createServiceRequest = (accessToken: string, deviceId: string, service: string) => ({
    type: CREATE_SERVICE_REQUEST,
    payload: {
        request: {
            url: `https://batxy5xsne.execute-api.us-east-2.amazonaws.com/dev/servicerequests`,
            headers: {
                "Authorization": `Bearer ${accessToken}`
            },
            method: "POST",
            data: {
                deviceId,
                service
            }
        }
    }
})

export const getAccessToken = () => ({
    type: GET_ACCESS_TOKEN,
    payload: {
        request: {
            method: 'post',
            url: 'https://jellycore.auth.us-east-2.amazoncognito.com/oauth2/token?client_id=4jiqftip20skdq6ns1pk1eeqq1&grant_type=client_credentials&scope=jellycore-admin-resource-server/read_secret',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic NGppcWZ0aXAyMHNrZHE2bnMxcGsxZWVxcTE6MTA5ZjZ1Zmpib2NyaGlpbmlhOHJjaW1yMG92bjc5anExbGhlNzhtYzdrczBxOHNjOWtpNA=='
            },
            data: ""
        }
    }
})