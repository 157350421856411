
import { NavBar } from "../components/NavBar";
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import en from './../images/en-logo.png';
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@material-ui/core/Button';
import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import * as SorayaActions from '../actions/SorayaActions';
import { PlantCardContainer } from "../components/PlantCard";

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return <Button variant="outlined" color="primary" onClick={() => loginWithRedirect()}>Log In</Button>;
};

const LogoutButton = () => {
    const { logout } = useAuth0();

    return (
        <button onClick={() => logout({ returnTo: window.location.origin })}>
            Log Out
        </button>
    );
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        alignContent: "center",
        justifyContent: "center"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    cardSection: {
        width: "50%",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    }
});


const renderLoginCard = (classes: any) => {
    return <Card className={classes.root} onClick={() => { }}>
        <CardContent className={classes.cardSection}>
            <span>Log in, yo</span>
        </CardContent>
        <CardContent className={classes.cardSection}>
            <LoginButton />
        </CardContent>
    </Card>
}

const renderUserInfo = (classes: any, user: any) => {

    return <Card className={classes.root} onClick={() => { }}>
        <CardContent className={classes.cardSection}>
            <img src={user.picture} alt={user.name} />
        </CardContent>
        <CardContent className={classes.cardSection}>
            <span>{user.name}</span>
            <span>{user.email}</span>
            <LogoutButton />
        </CardContent>
    </Card>
}

const renderPlants = (devices: Array<string>) => {
    console.log(devices);
    if (devices && devices.length > 0) {
        const plantCards = devices.map(d => <PlantCardContainer device={d} />);
        return plantCards;
    } else {
        return <></>
    }
}


const SorayaScreen = (props: {
    getAccessToken: () => void,
    getDevices: any,
    accessToken: any,
    devices: any
}) => {
    const classes = useStyles();
    const { user, isAuthenticated, isLoading } = useAuth0();

    useEffect(() => {
        if (!props.accessToken) {
            props.getAccessToken();
        }
    }, [props.accessToken]);

    useEffect(() => {
        if (!props.devices && props.accessToken) {
            props.getDevices(props.accessToken);
        } else {
            console.log(props.devices);
        }
    }, [props.accessToken]);

    return <div>
        <NavBar isProject={true} projectName="Soraya" />

        <div style={{ marginTop: 250 }}>
            <div className="projects-container">

                {!isAuthenticated && renderLoginCard(classes)}
                {isAuthenticated && renderUserInfo(classes, user)}
                {isAuthenticated && props.devices && renderPlants(props.devices)}
            </div>
        </div>
    </div>

}


const mapStateToProps = (state: any) => ({
    accessToken: state.soraya.accessToken,
    devices: state.soraya.devices
});

const mapDispatchToProps = {
    getAccessToken: SorayaActions.getAccessToken,
    getDevices: SorayaActions.getDevices
};

export const SorayaScreenContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SorayaScreen);